// @ts-nocheck
import { noop } from 'lodash';
import {
  createGesture,
  dragAction,
  pinchAction,
  wheelAction,
} from '@use-gesture/vanilla';

// actions
import { useActionWithDispatch, useSelector } from '@/Actions/useAction';

// utils
import { getFunctions } from '@/Utility/reflection';
import { cancelEvent } from '@/Utility/events';
import { Manager } from '../Manager';
import {
  MINIMUM_DRAG_THRESHOLD_DISTANCE,
  TOUCH_START_LONG_PRESS_TIMING,
} from '../../Constants/UI';
import { View } from '@/@types/shaper-types';

// hammer JS does not handle passive event handlers and
// the library appears to not be maintained anymore - this
// will toggle between using the passive event listeners by
// overriding the window level event handler. It should toggle
// this off as soon as it's finished
{
  const ael = window.addEventListener;
  window.addEventListener = function (...args) {
    if (/(pointer|wheel)/i.test(args?.[0])) {
      args[2] = { passive: false };
    }
    ael.apply(window, args);
  };
}

function isWithinViewport(event) {
  let check =
    event.target ||
    event.srcEvent?.target ||
    event.srcElement ||
    event?.srcEvent?.srcElement;
  while (check) {
    if (check.hasAttribute?.('data-viewport')) {
      return true;
    }

    check = check.parentElement;
  }
}

function stopEvent() {
  cancelEvent(this);
  return false;
}

// tracking the last known pointer position regardless of interaction
let lastKnownPointerPosition;
function saveLastKnownPointerPosition(event) {
  const { x, y } = event;
  lastKnownPointerPosition = { x, y };
}

export function getLastKnownPointerPosition() {
  return lastKnownPointerPosition;
}

window.addEventListener('pointermove', saveLastKnownPointerPosition);
window.addEventListener('pointerup', saveLastKnownPointerPosition);
window.addEventListener('pointerdown', saveLastKnownPointerPosition);
window.addEventListener('mousemove', saveLastKnownPointerPosition);
window.addEventListener('mouseup', saveLastKnownPointerPosition);
window.addEventListener('mousedown', saveLastKnownPointerPosition);

let functions = {};

// base class for handling interactions
export default class InteractionMode {
  theme;
  // holds the currently active InteractionMode
  static current = null;

  // tracked handlers
  handlers = {};

  // handles initializing the class
  init(view: View) {
    let preventedEventTypes;

    // helper function for binding event handlers
    const createInteractionResolver = (
      name,
      { isKeyboard, debug, ignoreUI, overrideActive, log = false } = {}
    ) => {
      const print = log ? console.log : () => {};

      if (log) {
        print('register', this);
      }

      // all event handling phases
      const resolutions = [
        [`onEvery${name}`, true, true],
        [`onActiveBefore${name}`, true, false],
        [`onBefore${name}`, false, false],
        [`onActive${name}`, true, false],
        [`on${name}`, false, false],
        [`onActiveAfter${name}`, true, false],
        [`onAfter${name}`, false, false],
      ];

      const notify = log ? (...args) => console.log(...args) : () => {};

      // creates an action handler
      notify('[interaction] create handler', name);
      return (event) => {
        print('[interaction]', name, event);

        // helper for debugging individual events
        if (debug) {
          // eslint-disable-next-line no-debugger
          debugger;
        }

        // must be active
        if (InteractionMode.current !== this) {
          print('[interaction] incorrect mode. Skipping...');
          return;
        }

        // for non-keyboard input, make sure it takes
        // place within the scope of a ui layer
        if (!isKeyboard) {
          let isUiEvent;

          // check for a path
          const path =
            event.srcEvent?.path ||
            event.event?.path ||
            event.path ||
            event.composedPath?.() ||
            event.srcEvent?.composedPath?.() ||
            event.event?.composedPath?.();
          if (path?.length) {
            isUiEvent = path.find(
              (el) => !!(el.hasAttribute && el.hasAttribute('data-viewport'))
            );
          }
          // weird issue with Safari for some events
          else if (isWithinViewport(event)) {
            isUiEvent = true;
          }

          // event was outside of the UI layer
          print('[interaction] isUiEvent:', isUiEvent);
          print('[interaction] ignoreUI:', ignoreUI);
          if (!isUiEvent && !ignoreUI) {
            print('[interaction] ignoring UI event', event);
            return;
          }
        }

        // if a keyboard event, ignore if it's an input element
        const { activeElement } = document;
        if (isKeyboard && activeElement) {
          // check for text inputs or content editable
          if (
            /(input|textarea)/i.test(activeElement.nodeName) ||
            activeElement.hasAttribute('contenteditable')
          ) {
            return;
          }
        }
        if (isKeyboard) {
          event.cancel = stopEvent;
        }

        if (/touch*/.test(event.type)) {
          event.isTouch = true;
          event.center = {
            x: event.touches[0]?.pageX,
            y: event.touches[0]?.pageY,
          };
        } else {
          event.center = {
            x: event.pageX || event.event?.pageX,
            y: event.pageY || event.event?.pageY,
          };
        }

        let ignoreRemaining = false;

        // always add this function each time to allow canceling
        // the remaining events
        this.manager.preventRemaining = () => (ignoreRemaining = true);

        // blocks event types for a period of time, the idea being that
        // some events need time to settle. For example, PinchEnd will fire
        // off a onPointerUp if both points are not released at the same time
        // so allowing some events to be blocked for a short time will prevent
        // misfiring the wrong events
        this.manager.preventEventTypes = (types, delay) => {
          preventedEventTypes = types;
          setTimeout(() => (preventedEventTypes = null), delay);
        };

        // start checking each of the key
        for (const [key, requiresActivation, alwaysActivate] of resolutions) {
          const handlers = this.handlers[key];
          notify('[interaction] checking', key);

          // nothing to resolve
          if (!handlers) {
            notify('[interaction] no handlers for', key);
            continue;
          }

          // check if any special rules
          if (preventedEventTypes?.includes(key)) {
            notify('[interaction] prevented type', key);
            continue;
          }

          // start looping through all registered instances
          notify('[interaction] execute for', key, handlers);
          for (const handler of handlers) {
            const exec = handler[key];
            const { interactionId } = handler;

            // check if being explicity ignored
            if (handler.ignoreWhen?.() === true) {
              notify('[interaction] actively ignoring', key);
              continue;
            }

            // forced activation
            if (alwaysActivate) {
              notify('[interaction] always activate', key);
              exec.call(handler, event, this.manager);
              continue;
            }

            // the flow has been interrupted
            if (ignoreRemaining) {
              notify('[interaction]', key, 'ignores remaining');
              continue;
            }

            // being blocked by another interaction
            if (
              !overrideActive &&
              !!this.activeInteraction &&
              this.activeInteraction !== interactionId
            ) {
              notify(
                '[interaction]',
                key,
                `(${interactionId})`,
                'not activeInteraction -- active:',
                this.activeInteraction
              );
              continue;
            }

            if (
              !requiresActivation ||
              (requiresActivation && handler.isActive)
            ) {
              // when move events start and end
              // toggle a special class to notify some UI
              // elements to ignore mouse events
              // this is a little more broad and sweeping since it's possible to
              // get in a locked state by leaving the browser in mid interaction
              // so it's easy to clear the state
              if (/(down|movestart)/i.test(key)) {
                document.body.classList.add('active-pointer-interaction');
              } else if (/(up|moveend)/i.test(key)) {
                setTimeout(() => {
                  document.body.classList.remove('active-pointer-interaction');
                });
              }

              print('[interaction] execute', handler);
              const result = exec.call(handler, event, this.manager);

              // requesting to stop testing
              if (result === false) {
                ignoreRemaining = true;
              }
            }
          }
        }
      };
    };

    const pointerDownHandler = createInteractionResolver('PointerDown');
    const pointerUpHandler = createInteractionResolver('PointerUp');
    const dragStartHandler = createInteractionResolver('PointerMoveStart');
    const dragHandler = createInteractionResolver('PointerMove');
    const dragEndHandler = createInteractionResolver('PointerMoveEnd');
    const pinchStartHandler = createInteractionResolver('PinchStart');
    const pinchMoveHandler = createInteractionResolver('PinchMove');
    const pinchEndHandler = createInteractionResolver('PinchEnd');
    const longPressActivateHandler =
      createInteractionResolver('LongPressActivate');
    const longPressReleaseHandler =
      createInteractionResolver('LongPressRelease');
    const mouseWheelHandler = createInteractionResolver('MouseWheel');
    const doubleClickHandler = createInteractionResolver('DoubleClick');

    const interactionManager = this.ref?.current;
    const Gesture = createGesture([dragAction, pinchAction, wheelAction]);

    if (interactionManager) {
      this.gesture = new Gesture(
        interactionManager,
        {
          onPinch: (state) => {
            const {
              origin,
              movement,
              memo: prevMemo,
              event,
              cancel,
              first,
              last,
            } = state;

            if (event instanceof WheelEvent) {
              return cancel();
            }

            const nextMemo = (() => {
              if (first) {
                return {
                  prevOrigin: { x: origin[0], y: origin[1] },
                  initOrigin: { x: origin[0], y: origin[1] },
                };
              }

              return {
                ...prevMemo,
                prevOrigin: { x: origin[0], y: origin[1] },
              };
            })();

            const clientDelta = (() => {
              if (prevMemo?.prevOrigin.x && prevMemo?.prevOrigin.y) {
                return {
                  x: origin[0] - prevMemo.initOrigin.x,
                  y: origin[1] - prevMemo.initOrigin.y,
                };
              }

              return {
                x: 0,
                y: 0,
              };
            })();

            const nextZoomLevel = movement[0];

            if (first) {
              pinchStartHandler(state);
            } else if (last) {
              pinchEndHandler(state);
            } else {
              pinchMoveHandler({ nextZoomLevel, clientDelta, ...state });
            }

            return nextMemo;
          },
          onWheel: (state) => {
            mouseWheelHandler(state.event);
          },
          onDrag: (state) => {
            if (!state.pinching) {
              if (state.first) {
                dragStartHandler(state);
              } else if (state.last) {
                dragEndHandler(state);
                return;
              } else {
                const { cancel } = state;
                if (state.pinching) {
                  cancel();
                }
                state.deltaX = state.movement[0];
                state.deltaY = state.movement[1];
                dragHandler(state);
              }
            } else {
              dragEndHandler(state);
            }
          },
        },
        {
          drag: {
            filterTaps: true,
            delay: 1000,
            scaleBounds: {
              min: 0.001,
              max: Infinity,
            },
          },
          pinch: {
            pointer: {
              touch: false,
            },
            preventDefault: true,
            eventOptions: {
              passive: false,
            },
          },
        }
      );
    }

    const mouseMoveHandler = createInteractionResolver('MouseMove', {
      isMouse: true,
    });
    // a catch all handler for any time the window may lose interactions
    // such as mouse leaving or tabs changing
    const windowExitHandler = createInteractionResolver('WindowExit', {
      ignoreUI: true,
    });

    // general mouse events
    window.addEventListener('mousemove', mouseMoveHandler);

    // losing focus
    window.addEventListener('blur', windowExitHandler);
    document.addEventListener('visibilitychange', (event) => {
      if (document.visibilityState !== 'visible') {
        windowExitHandler(event);
      }
    });

    // check for the mouse leaving the browser
    document.body.addEventListener('mouseleave', (event) => {
      if (
        event.clientX < 0 ||
        event.clientX > window.innerWidth ||
        event.clientY < 0 ||
        event.clientY > window.innerHeight
      ) {
        windowExitHandler(event);
      }
    });

    const keyDownHandler = createInteractionResolver('KeyDown', {
      isKeyboard: true,
    });
    const keyPressHandler = createInteractionResolver('KeyPress', {
      isKeyboard: true,
    });
    const keyUpHandler = createInteractionResolver('KeyUp', {
      isKeyboard: true,
    });

    // key presses
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keypress', keyPressHandler);
    window.addEventListener('keyup', keyUpHandler);

    let longPressActivationTimer;
    let longPressOrigin;
    let isLongPress;
    const dblTouchTapMaxDelay = 300;
    let latestTouchTap = {
      time: 0,
      target: null,
    };

    function activateLongPress(event) {
      isLongPress = false;
      clearTimeout(longPressActivationTimer);

      // can activate
      const distance = Math.atan2(
        longPressOrigin.y - lastKnownPointerPosition.y,
        longPressOrigin.x - lastKnownPointerPosition.x
      );

      if (
        lastKnownPointerPosition &&
        distance < MINIMUM_DRAG_THRESHOLD_DISTANCE
      ) {
        longPressActivateHandler(event);
        isLongPress = true;
      }
    }

    function tryLongPress(event) {
      if (event.touches?.[0]) {
        const touchTap = {
          time: new Date().getTime(),
          target: event.currentTarget,
        };
        const isDoubleTap =
          touchTap.target === latestTouchTap.target &&
          touchTap.time - latestTouchTap.time < dblTouchTapMaxDelay;
        latestTouchTap = touchTap;
        if (isDoubleTap) {
          doubleClickHandler(event);
        }
      }
      const source = event.touches?.[0] || event;
      const { screenX: x, screenY: y } = source;
      const evt = event;

      // save where the long press started from
      longPressOrigin = { x, y };

      // queue up the long press
      longPressActivationTimer = setTimeout(
        () => activateLongPress(evt),
        TOUCH_START_LONG_PRESS_TIMING
      );
    }

    function endLongPress(event) {
      if (isLongPress) {
        longPressReleaseHandler(event);
      }

      isLongPress = false;
      clearTimeout(longPressActivationTimer);
    }

    const mousedownEvent = tryLongPress;
    const touchstartEvent = tryLongPress;
    const mouseupEvent = endLongPress;
    const touchendEvent = endLongPress;

    functions.mouseup = [...(functions.mouseup || []), mouseupEvent];
    functions.mousedown = [...(functions.mousedown || []), mousedownEvent];
    functions.pointerup = [...(functions.pointerup || []), pointerUpHandler];
    functions.pointerdown = [
      ...(functions.pointerdown || []),
      pointerDownHandler,
    ];
    functions.touchstart = [...(functions.touchstart || []), touchstartEvent];
    functions.touchend = [...(functions.touchend || []), touchendEvent];
    functions.dblclick = [...(functions.dblclick || []), doubleClickHandler];

    window.addEventListener('mousedown', mousedownEvent);
    window.addEventListener('mouseup', mouseupEvent);
    window.addEventListener('pointerup', pointerUpHandler);
    window.addEventListener('pointerdown', pointerDownHandler);
    window.addEventListener('touchstart', touchstartEvent);
    window.addEventListener('touchend', touchendEvent);
    window.addEventListener('dblclick', doubleClickHandler);

    // setup each of the handlers
    for (const Type of this.getInteractionHandlers(view)) {
      const interaction = new Type(this);
      this.register(interaction);
    }
  }

  getInteractionHandlers(view: View) {
    throw new Error(
      'InteractionMode.getInteractionHandlers is a virtual method and needs to be overridden by the child class'
    );
  }

  // handles registering functions
  register(interaction) {
    const functionNames = getFunctions(interaction);
    for (const name of functionNames) {
      // check for the "on" handler prefix
      if (name.substr(0, 2) !== 'on') {
        continue;
      }

      // make sure it's a function
      const func = interaction[name];
      if (typeof func !== 'function') {
        continue;
      }

      // save the handler
      this.handlers[name] = this.handlers[name] || [];
      this.handlers[name].push(interaction);

      // preloading
      if (interaction.init) {
        interaction.init();
        interaction.init = noop;
      }
    }
  }

  // used to create dispatchable actions in interactions
  createAction(action, ...args) {
    return useActionWithDispatch(action, this.dispatch, undefined, ...args);
  }

  cleanUpWindowListeners() {
    this.handlers = {};
    Object.keys(functions).forEach((f) => {
      functions[f].forEach((func) => {
        window.removeEventListener(f, func);
        delete functions[f];
      });
    });
  }

  // binds app state to an interaction handler
  activate(dispatch, ref, view: View) {
    Object.assign(this, { dispatch, useSelector, ref });

    // prevent multiple of the same hooks attaching themselves
    this.cleanUpWindowListeners();
    // mark as active so when events fire, these
    // are skipped by default
    InteractionMode.current = this;

    this.init(view);
    this.manager = new Manager();

    return this.gesture;
  }
}
