import ScaleViewportAction from '@/Actions/ScaleViewport';
import BaseInteraction from './BaseInteraction';
import PanAndZoomViewportAction from '@/Actions/PanAndZoomViewport';

// scale at this increment
const SCALE_INCREMENT = 0.05;

export default class PanAndZoomViewportInteraction extends BaseInteraction {
  interactionId = 'Pan and Zoom';

  onPinchStart(event) {
    this.origin = {
      x: event.center.x,
      y: event.center.y,
    };

    // reset the zoom level
    this.activeZoom = 1;
    this.lastScaledValue = 0;

    this.action = this.createAction(PanAndZoomViewportAction);
    this.setActive(true);
  }

  onActivePinchMove(event) {
    if (this.priorScale === undefined) {
      const viewport = this.getViewport();
      this.priorScale = viewport.scale;
    }

    // determine the current requested scale
    const scale = this.activeZoom - event.movement[0];

    // only apply the scaling if the scale threshold has passed
    if (Math.abs(scale - this.lastScaledValue) > SCALE_INCREMENT) {
      // scale and save the updated value
      this.scaleViewport(scale - this.lastScaledValue, this.origin);
      this.lastScaledValue = scale;
    }
  }

  onPinchEnd(event, manager) {
    this.setActive(false);

    manager.preventEventTypes(['onPointerUp'], 250);
    // this.release();
  }

  onEveryWindowExit() {
    this.setActive(false);

    this.action?.resolve();
  }

  scaleViewport(scale, point) {
    const action = this.createAction(ScaleViewportAction);
    action.scaleBy(scale, point);
  }
}
