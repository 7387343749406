import React from 'react';
import TranslationText from '../../TranslationText/TranslationText';
import FooterMenu from '../FooterMenu';
import UIState from '@/UILayer/State/UIState';

export default function TextInsertFooterMenu(props) {
  function handleCommit() {
    UIState.reset();
    UIState.render();
    props.onCommit?.();
  }

  return (
    <FooterMenu>
      <FooterMenu.Row>
        <FooterMenu.Button dataCy='place-text' onClick={handleCommit}>
          <TranslationText i18nKey='place-text'>Place Text</TranslationText>
        </FooterMenu.Button>
        <FooterMenu.Close onClick={props.onClose} />
      </FooterMenu.Row>
    </FooterMenu>
  );
}
