import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import SelectionNetInteraction from '@/InteractionsManager/Interactions/SelectionNet';
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
// import PanModeTranslateViewportInteraction from '@/InteractionsManager/Interactions/PanModeTranslateViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import TransformGroupsInteraction from '@/InteractionsManager/Interactions/TransformGroups';

// default actions
import ActivateAnchorEditInteraction from './Interactions/ActivateAnchorEdit';
import KeyboardShortcutsInteraction from './Interactions/KeyboardShortcuts';
import EmptyInteraction from './Interactions/Empty';
import InsertPointInteraction from './Interactions/InsertPoint';
import PanAndZoomViewportInteraction from '../../Interactions/PanAndZoomViewport';
import TextEdit from './Interactions/TextEdit';
import { View } from '@/@types/shaper-types';
import PreviewSelectInteraction from './Interactions/PreviewSelect';

export default class DefaultMode extends InteractionMode {
  theme = 'default';

  getInteractionHandlers(view: View) {
    return view !== 'preview'
      ? [
          PanAndZoomViewportInteraction,
          InsertPointInteraction,
          SelectionNetInteraction,
          TranslateViewportInteraction,
          ActivateAnchorEditInteraction,
          KeyboardShortcutsInteraction,
          TransformGroupsInteraction,
          ZoomViewportInteraction,
          EmptyInteraction,
          TextEdit,
        ]
      : [
          PreviewSelectInteraction,
          ZoomViewportInteraction,
          EmptyInteraction,
          PanAndZoomViewportInteraction,
          TranslateViewportInteraction,
        ];
  }
}
